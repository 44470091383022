import React from 'react';
import { Col, Row } from 'react-bootstrap';

const Home = (props) => {

  
  return (
    <>
      <Row>
        <Col xs={14} md={10}>
        </Col>
        <Col style={{
          alignContent: 'right'
        }}>
          <img src={props.user.avatar} width="64px"></img>
        </Col>
        <Col>
          <span className='fw-bold'>{props.user.username}</span>
        </Col>
      </Row>
    </>
  );
};

export default Home;