import React, { Component } from 'react';
import { Util } from '../../util/stream-util';
import IVSBroadcastClient, { STANDARD_PORTRAIT } from 'amazon-ivs-web-broadcast';
import { Card, Badge, Button, Row, Col } from 'react-bootstrap';
import './Stream.css';
import AWS from 'aws-sdk';

AWS.config.region = 'us-east-1'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: 'us-east-1:4b3bfebc-a991-4cd2-b3ea-a55d492c7b46',
});

const util = new Util();
const lambda = new AWS.Lambda();

class Stream extends Component {

  constructor() {
    super();
    this.previewRef = React.createRef();
    this.state = {
      isBroadcasting: false,
      ingestEndpoint: '',
      streamKey: '',
      channelArn: '',
      showModal: false,
      cameraOptions: [],
      micOptions: [],
      selectedVideoDeviceId: '',
      selectedAudioDeviceId: '',
      broadcastClient: null,
      videoStream: null,
      audioStream: null,
      startTime: 0,
      disabledBroadcastButton: false
    };
  }

  async componentDidMount() {
    const settings = JSON.parse( localStorage.getItem('web-broadcast') || '{}' );
    const response = await this.fetchSettings();
    const resp = JSON.parse(JSON.parse(response.Payload).body);
    this.setState({
      ingestEndpoint: resp.IngestEndpoint,
      streamKey: resp.StreamKey,
      channelArn: resp.ChannelArn,
    });
    this.setState({
      // ingestEndpoint: settings.ingestEndpoint,
      // streamKey: settings.streamKey,
      selectedAudioDeviceId: settings.selectedAudioDeviceId,
      selectedVideoDeviceId: settings.selectedVideoDeviceId,
      // channelArn: settings.channelArn,
    });

    await util.handlePermissions();

    let client = IVSBroadcastClient.create({
      streamConfig: STANDARD_PORTRAIT,
      ingestEndpoint: this.state.ingestEndpoint,
    });

    let devices = await util.getDevices(settings.selectedVideoDeviceId, settings.selectedAudioDeviceId);
    const videoStream = await util.createVideoStream(client, devices.selectedVideoDeviceId);
    const audioStream = await util.createAudioStream(client, devices.selectedAudioDeviceId);

    const newState = { 
      broadcastClient: client,
      audioStream, 
      videoStream,
      selectedAudioDeviceId: devices.selectedAudioDeviceId,
      selectedVideoDeviceId: devices.selectedVideoDeviceId,
      cameraOptions: devices.cameraOptions,
      micOptions: devices.micOptions,
    };
    this.setState(newState, () => {
      this.previewVideo();
    });
  }

  fetchSettings = () => {
    const params = {
      FunctionName: 'createStream',
      InvocationType: 'RequestResponse'
    };
    return lambda.invoke(params).promise();
  };

  startStream = () => {
    this.setState({
      disabledBroadcastButton: true
    });
    const pl = JSON.stringify({
      ChannelArn: this.state.channelArn,
      StreamerId: this.props.user.id,
      StreamerWallet: this.props.user.wallet
    });
    console.log(pl);
    const params = {
      FunctionName: 'startStream-function-5KmsuHNMQDR3',
      InvocationType: 'RequestResponse',
      Payload: pl
    };
    return lambda.invoke(params).promise();
  };

  stopStream = () => {
    const pl = JSON.stringify({
      ChannelArn: this.state.channelArn,
      StartTime: this.state.startTime
    });
    console.log(pl);
    const params = {
      FunctionName: 'stopStream-function-0S6mqSkvzIl5',
      InvocationType: 'RequestResponse',
      Payload: pl
    };
    return lambda.invoke(params).promise();
  };

  previewVideo = () => {
    this.state.broadcastClient.attachPreview(this.previewRef.current);
  };

  handleBroadcast = async () => {
    if(!this.state.isBroadcasting) {
      try {
        await this.state.broadcastClient.startBroadcast(this.state.streamKey);
        this.setState({isBroadcasting: true});
        const res = await this.startStream();
        this.setState({startTime: JSON.parse(res.Payload).startTime});
      }
      catch(e) {
        console.error(e);
        this.setState({isBroadcasting: false});
      }
      this.setState({
        disabledBroadcastButton: false
      });
    } else {
      try {
        await this.state.broadcastClient.stopBroadcast();
        await this.stopStream();
      }
      catch(e) {
        console.error(e);
      }
      finally {
        this.setState({isBroadcasting: false});
      }
    }
  };

  render() {
    return (
      <>
        <Row>
          <Col lg='8' xs='12' className='offset-lg-2 mb-lg-3'>
            <Card bg='dark' className="mb-2">
              <Card.Header  className='d-flex justify-content-end'>
                <div className='align-self-center'>
                  <Badge 
                    bg={this.state.isBroadcasting ? 'badge bg-success' : 'badge bg-danger'} 
                    className='me-1'>
                    <b>Stream: </b>{this.state.isBroadcasting ? 'Broadcasting' : 'Offline'}
                  </Badge>
                </div>
              </Card.Header>
              <Card.Body className='d-flex justify-content-center'>
                <div className='position-relative d-flex align-items-center'>
                  <canvas ref={this.previewRef} id='broadcast-preview' className='align-self-center'></canvas>
                </div>
              </Card.Body>
              <Card.Footer className='d-flex justify-content-center'>
                <Button 
                  className='d-flex w-100 justify-content-center'
                  size='lg'
                  disabled={this.state.disabledBroadcastButton}
                  variant={this.state.isBroadcasting ? 'danger' : 'primary'} 
                  onClick={this.handleBroadcast}>
                  {this.state.isBroadcasting ? 'Stop Broadcast' : 'Broadcast'}
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default Stream;