import React, { useState, useEffect } from 'react';
import './App.css';
import {Template} from './Template';
import { LinkContainer } from 'react-router-bootstrap';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/home/Home';
import Stream from './components/stream/Stream';
import Feed from './components/feed/Feed';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './assets/haura_icon_white3.svg';
import Memos from './components/memos/Memos';

AWS.config.region = 'us-east-1'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: 'us-east-1:4b3bfebc-a991-4cd2-b3ea-a55d492c7b46',
});
const lambda = new AWS.Lambda();

const App = () => {
  const [user, setUser] = useState({});

  const params = new URLSearchParams(window.location.search);
  
  if(params.get('authToken') == null && window.localStorage.getItem('authToken') == null) {
    window.location.href = 'https://app.handcash.io/#/authorizeApp?appId=6462082a093ef66e409aa3ff';
  }

  if(params.get('authToken') != null) {
    window.localStorage.setItem('authToken', params.get('authToken'));
  }

  useEffect(() => {
    const getUserInfo = async () => {
      const params = {
        FunctionName: 'getUserInfo-function-wbEsHSU9wYjl',
        InvocationType: 'RequestResponse',
        Payload: JSON.stringify({
          AuthToken: window.localStorage.getItem('authToken')
        })
      };
      return lambda.invoke(params).promise();
    };
    getUserInfo()
      .then(res => {
        const user = JSON.parse(res.Payload);
        setUser(user);
      })
      .catch(console.error);
  }, []);


  return (
    <div style={{ 
      backgroundImage: 'url("/background.png")',
      height: '100vh'
    }}>
      <Template>
    
        <BrowserRouter>
          <Navbar bg='dark' variant='dark' className='navbar-expand-lg mb-2 border-secondary rounded'>
            <Container>
              <Navbar.Brand href="/" className='align-center'>
                <img src={logo} width='64px'/>
              </Navbar.Brand>
              <Navbar.Collapse>
                <Nav>
                  <LinkContainer to='/'>
                    <Nav.Link>Home</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to='/stream'>
                    <Nav.Link>Stream</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to='/feed/0'>
                    <Nav.Link>Feed</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to='/memos'>
                    <Nav.Link>Memos</Nav.Link>
                  </LinkContainer>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <Routes>
            <Route path='feed/:id' element={<Feed user={user}/>} />
            <Route path='stream' element={<Stream user={user}/>} />
            <Route path='memos' element={<Memos user={user}/>} />
            <Route path='/' element={<Home user={user}/>} />
          </Routes>
        </BrowserRouter>
      </Template>
    </div>
    
  );
};

export default App;
