import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import { Button, Col, Row, Table } from 'react-bootstrap';

AWS.config.region = 'us-east-1'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: 'us-east-1:4b3bfebc-a991-4cd2-b3ea-a55d492c7b46',
});
const lambda = new AWS.Lambda();

const Memos = (props) => {

  const [memos, initMemos] = useState([]);

  const getMemos = async () => {
    const lparams = {
      FunctionName: 'getMemos',
      InvocationType: 'RequestResponse',
      Payload: JSON.stringify({
        'userId': props.user.id
      })
    };
    return lambda.invoke(lparams).promise();
  };

  useEffect(() => {
    getMemos()
      .then((res) => {
        var payload = JSON.parse(JSON.parse(res.Payload).body);
        console.log('response:', payload);
        initMemos(payload);
      });
  }, []);

  return (<>
    <table class="table table-dark table-striped">
      <thead>
        <tr>
          <th>Year</th>
          <th>Month</th>
          <th>Day</th>
          <th>Time HH:MM</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {
          memos.map((mem => {
            console.log(mem);
            var parts = mem.split('/');
            return(<tr>
              <td>{parts[1]}</td>
              <td>{parts[2]}</td>
              <td>{parts[3]}</td>
              <td>{parts[4]}:{parts[5]}</td>
              <td><a href={'https://haura-ready-memos.s3.amazonaws.com/'+mem} download><Button>Download</Button></a></td>
            </tr>);
          }))
        }
      </tbody>
    </table>
  </>);
};

export default Memos;