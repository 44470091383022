import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Badge, Button, Row, Col } from 'react-bootstrap';
import AWS from 'aws-sdk';
import VideoPlayer from '../videoPlayer/VideoPlayer';
import './Feed.css';

AWS.config.region = 'us-east-1'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: 'us-east-1:4b3bfebc-a991-4cd2-b3ea-a55d492c7b46',
});
const lambda = new AWS.Lambda();

const Feed = (props) => {

  const params = useParams();
  const navigate = useNavigate();


  const [playbackUrls, initPlaybackUrls] = useState([]);
  const [isPremium, initIsPremium] = useState(false);
  const [premiumSessions, initPremiumSessions] = useState(new Map());

  const getPlaybackUrl = async () => {
    const lparams = {
      FunctionName: 'getStreams',
      InvocationType: 'RequestResponse'
    };
    return lambda.invoke(lparams).promise();
  };

  const startPremium = async () => {
    const lparams = {
      FunctionName: 'startPremium',
      InvocationType: 'RequestResponse',
      Payload: JSON.stringify({
        UserId: props.user.id,
        WalletId: props.user.wallet,
        Token: window.localStorage.getItem('authToken'),
        StreamerId: playbackUrls.streams[parseInt(params.id)].Metadata.UserName,
        StreamId: playbackUrls.streams[parseInt(params.id)].stream.channelArn,
        StreamStartTime: playbackUrls.streams[parseInt(params.id)].stream.startTime
      })
    };
    return lambda.invoke(lparams).promise();
  };

  const stopPremium = async () => {
    console.log('Stopping session: ', premiumSessions.get(params.id));
    const lparams = {
      FunctionName: 'stopPremium',
      InvocationType: 'RequestResponse',
      Payload: JSON.stringify({
        SessionId: premiumSessions.get(params.id),
        WatcherId: props.user.id
      })
    };
    return lambda.invoke(lparams).promise();
  };

  const handlePremium = () => {
    if (isPremium) {
      stopPremium()
        .then(response => {
          console.log(response);
          console.log(premiumSessions);
          initIsPremium(false);
          var payload = JSON.parse(JSON.parse(response.Payload).body);
          console.log(payload.Total);
          premiumSessions.delete(params.id);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      startPremium()
        .then(response => {
          console.log(response);
          initIsPremium(true);
          var payload = JSON.parse(JSON.parse(response.Payload).body);
          premiumSessions.set(params.id, payload.SessionId);
          console.log(premiumSessions);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  
  useEffect(() => {
    getPlaybackUrl()
      .then((res) => {
        var payload = JSON.parse(JSON.parse(res.Payload).body);
        console.log('foo:"', payload);
        console.log(payload.streams[parseInt(params.id)].stream.playbackUrl);
        initPlaybackUrls(payload);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  
  return (
    <>
      <Row>
        <Col lg='8' xs='12' className='offset-lg-2 mb-lg-3'>
          <Card bg='dark' className="mb-2">
            <Card.Header className='d-flex justify-content-end'>
              <div className='align-self-center'>
                <Badge bg={isPremium ? 'badge bg-danger' : 'badge bg-success'} className='me-1'>
                  <b>Premium: </b>{isPremium ? 'On' : 'Off'}
                </Badge>
              </div>
            </Card.Header>
            <Card.Body className='d-flex justify-content-center'>
              <div className='position-relative d-flex align-items-center'>
                {playbackUrls.streams != undefined && playbackUrls.streams.length > 0 ? (
                  <VideoPlayer playbackUrl={playbackUrls.streams[parseInt(params.id)].stream.playbackUrl} className='align-self-center' id='video-player'/>
                )  : (
                  <span className='fw-bold' style={{color:'white'}} >No streams available</span>
                )}
              </div>
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col className='d-flex justify-content-center'>
                  { playbackUrls.streams != undefined && playbackUrls.streams.length > 1 ? (
                    <Button className='d-flex align-items-center justify-content-center' size='lg' onClick={() => {navigate(`/feed/${Number(params.id)-1}`);}}>{'<'}</Button>
                  ) : ('')}
                  <Button
                    className='d-flex w-100 justify-content-center premium-button'
                    size='lg'
                    variant={isPremium ? 'success' : 'danger'} 
                    onClick={handlePremium}>
                    {isPremium ? 'Stop Premium' : 'Start Premium'}
                  </Button>
                  { playbackUrls.streams != undefined && playbackUrls.streams.length > 1 ? (
                    <Button className='d-flex align-items-center justify-content-center' size='lg' onClick={() => {navigate(`/feed/${Number(params.id)+1}`);}}>{'>'}</Button>
                  ) : ('')}
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Feed;
